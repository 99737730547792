import { useState, useEffect } from "react";
import url from "../../../url ";

export function AddCards(props) {
    const { setPopupForAddCardsOpen, clubID } = props;
    const [units, setUnits] = useState(undefined);
    const [firstNumber, setFirstNumber] = useState('');

    useEffect(() => {
        async function getFirstNumber() {
            const res = await fetch(`${url}/admin/add-cards/first-number`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    clubId: clubID,
                }),
                credentials: "include",
            });
            if (res.status === 200) {
                const data = await res.json();
                setFirstNumber(data.firstNumber);
            } else {
                alert("אירעה שגיאה");
            }
        }
        getFirstNumber();
    }, [clubID]);

    async function submit (e) {
        e.preventDefault();
        const res = await fetch(`${url}/admin/add-cards/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                clubId: clubID,
                units: units,
                firstNumber: firstNumber,
            }),
            credentials: "include",
        });
        if (res.status === 200) {
            const data = await res.blob();
            const link = window.URL.createObjectURL(data);
            const a = document.createElement('a');
            a.href = link;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setPopupForAddCardsOpen(false);
        } else {
            alert("אירעה שגיאה");
        }
    }

    return (
        <div 
            className="App" 
            style={{
                position: "fixed",
                zIndex: 10,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
                width: "auto",
                maxWidth: "90%",
                overflow: "auto",
                height: "auto",
                maxHeight: "90%",
            }}
        >
            <h1>הוספת כרטיסים</h1>
            <form onSubmit={submit}
            style={{
                backgroundColor: "white",
                width: "auto",
                maxWidth: "90%",
                height: "auto",
                maxHeight: "90%",
            }}
            >
                <label htmlFor="units">כמות כרטיסים</label>
                <input type="number" id="units" name="units" required value={units} onChange={e => setUnits(e.target.value)}/>
                <label htmlFor="firstNumber">מספר ראשון</label>
                <input type="text" id="firstNumber" name="firstNumber" required pattern="[0-9]{8}" value={firstNumber} onChange={e => setFirstNumber(e.target.value)}/>
            <button type="submit">הוסף</button>
            <button type="button" onClick={() => setPopupForAddCardsOpen(false)}>ביטול</button>
            </form>
        </div>
    );
}