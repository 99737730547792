import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Table from "../main/table/table";
import IndexPage from "../main/table/indexPage/indexPage";
import CardReader from "./reed";
import { StorFamiliar } from "./storFamiliar";
import urll from "../url ";
import { useRef } from "react";
import url from '../url ';

export function CardCode(props) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [code, setCode] = useState("");
  const [code1, setCode1] = useState("");

  const [history, setHistory] = useState([]);
  const [sumOfRows, setSumOfRows] = useState(0);
  const [page, setPage] = useState(0);
  const [isAlrt, setIsAlrt] = useState(false);
  const [idUsege, setIdUsege] = useState("");
  const [iscode, setisCode] = useState(false);
  const [name, setName] = useState('')
  const [connectAs, setConnectAs] = useState('')
  const { id, typeOFHistory } = useParams();
  const navigate = useNavigate();
  const [historyType, setHistoryType] = useState(typeOFHistory);


  const textInputRef = useRef(null);

  useEffect(() => {
    textInputRef.current.focus();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 768);
    });
  }, []);
  const reqHistory = async () => {
      try {
        console.log(historyType);
        const url = `${urll}/store/${id}/getLestUseges/${historyType}`;
        const res = await fetch(url, {
          method: "POST",
          credentials: 'include',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pos: page,
          }),
        });
        const data = await res.json();
        const { rows, sumOfRows } = data;
        setHistory(rows);
        setSumOfRows(sumOfRows);
      } catch (e) {
        console.log(e);
      }
    };
  useEffect(() => {
    reqHistory();
    navigate(`/CardCode/${id}/${historyType}`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, id, historyType])
  const hndelPage = async (i) => {
    if (i < 0 || i > Math.floor(history / 30)) {
      return;
    }
    setPage(i);
  };
  const buy = () => {
    // nn = nn? nn : code
    // console.log(co);
    // navigate(`/storFamiliar/${id}/${code}`);
    // console.log(code1);
    setisCode(true);
  };
  function handleKeyPress(event) {
    if (!code) {
      return;
    }
    if (event.key === "Enter") {
      //   console.log(code);
      setCode1(code);
      buy();
      return;
    }
  }
  function ok(event) {
    if (!code) {
      return;
    }
    setCode1(code);
    buy();
    return;
  }
  //   console.log(code1);
  const alrt = (id) => {
    setIsAlrt(true);
    setIdUsege(id);
  };
  const Usege = async () => {
    try {
      const url = `${urll}/store/${id}/cancelUsege/${historyType}`;
      const res = await fetch(url, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: idUsege,
        }),
      });
      const data = await res.json();
      setIdUsege(null);
      if (data.status === "success") {
        alert("הקניה בוטלה");
        setIsAlrt(false);
        window.location.reload();
        return;
      }
    } catch (e) {
      console.log(e);
    }
    alert("שגיאה נסה שוב");
    setIsAlrt(false);
  };
  // console.log(history);
  const handlLoguot = async () => {
    try {
      await fetch(`${url}/user/logout`, {
        credentials: 'include'
      });
    } catch (e) {
      console.log(e.message);
    }
  };
  useEffect(() => {
  const getNameAndConnectAs = async ()=>{
    try {
      const res = await fetch(`${url}/${'store' + (id? `/${id}`:'')}/get-name-and-connect-as`, {
        credentials: 'include',
        method: 'post'
      });
      if(res.status === 401 || res.status === 403){
        navigate('/login')
      }
      const data = await res.json()
      setName(data.name)
      setConnectAs(data.connectAs)
      console.log(data);
    }
    catch (e) {
      console.error(e);
    }
  }
  getNameAndConnectAs()
  }, [id])
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{margin: "10px", width: "50%", height: "50px", display: "inline-block" }}
        >
           <a href="/login" onClick={handlLoguot} className="loguot">
            יציאה
          </a>
          <button style={{backgroundColor:"white", border:"", color:'black'}} onClick={()=> navigate("/Connection")}>אפשריות כניסה</button>
        </div>
        <div
          style={{ textAlign: "left", width: "50%", display: "inline-block", paddingLeft: '5px' }}
        >
          <img src="/zz.png" alt="סמל" width="150" height="60" />
        </div>
      </div>
      {iscode === false && (
        <div style={{ textAlign: "center" }}>
          <h3>{name}</h3>
          <h3>{connectAs}</h3>
          <CardReader
            setCode={setCode1}
            startChars={["%", ";", "ף"]}
            endChars={["?", "!"]}
            onCardRead={(code) => console.log("Card Code:", code)}
            buy={buy}
          />
          <div
            className="main"
            style={{
              width: isMobile ? "70%" : "25%",
              border: "1px black solid",
              padding: isMobile ? "10px" : "60px",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <label htmlFor="aa" style={{ textAlign: "center" }}>
              העבר כרטיס או הקלד 8 ספרות אחרונות של הכרטיס{" "}
            </label>
            <br />
            <input
              type="number"
              ref={textInputRef}
              onKeyDown={handleKeyPress}
              onKeyUp={(e) => handleKeyPress(e)}
              id="aa"
              style={{ margin: "10px" }}
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <br />
            <button onClick={ok}>אישור</button>
          </div>
          <button onClick={() => setHistoryType(`money`)}>היסטוריית קניות בכסף</button>
          <button onClick={() => setHistoryType(`vouchers`)}>היסטוריית קניות בשוברים</button>
          {history.length > 0 && (
            <>
              <h3>היסטוריית קניות</h3>

              <Table
                titles={Object.keys(history[0])}
                rows={history}
                table={"history"}
                // hndelSort={hndelSort}
                isEdit={false}
                permission={"storep"}
                permissionID={id}
                alrt={alrt}
              // Unloading={Unloading}
              />
              <IndexPage page={page} pages={sumOfRows} hndelPage={hndelPage} />
              <br />
              {isAlrt && (
                <div
                  style={{
                    fontSize: "10px",
                    position: "fixed",
                    border: "1px black solid",
                    top: "50%", // שינוי כדי להצמיד לאמצע העמוד
                    left: "50%", // שינוי כדי להצמיד לאמצע העמוד
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "white",
                    borderRadius: "5px",
                  }}
                >
                  <p style={{ fontSize: "15px", margin: "5px" }}>
                    אתה בטוח שברצונך לבטל את הקניה
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button onClick={Usege}>אישור </button>
                    <button onClick={() => setIsAlrt(false)}>ביטול</button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {iscode && (
        <div>
          <StorFamiliar id={code1} setisCode={setisCode} />
        </div>
      )}
    </div>
  );
}

// function setupCardReader(callback) {
//   const startChars = ["%", ";"];
//   const endChars = ["?", "!"];
//   let cardCode = "";
//   let readingCard = false;

//   document.addEventListener("keydown", (event) => {
//     if (
//       ["Shift", "Control", "Alt", "Meta", "CapsLock", "Tab", "Escape"].includes(
//         event.key
//       )
//     ) {
//       return;
//     }

//     if (!readingCard && startChars.includes(event.key)) {
//       readingCard = true;
//       cardCode = event.key;
//     } else if (readingCard) {
//       cardCode += event.key;

//       if (endChars.includes(event.key)) {
//         callback(cardCode); // Call the callback function with the card code
//         readingCard = false;
//         cardCode = "";
//       }
//     }
//   });
// }
